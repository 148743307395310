<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.incidents_types_reports') }}</h1>
        <FiltersBar :with-search="false"
                    ref="filtersBar"></FiltersBar>
        <IncidentsTypesReportTable :loader="loader"
                                   :xls-loader="xlsLoader"
                                   :pdf-loader="pdfLoader"
                                   :filters="generateFiltersForRequest()"
                                   @get-file="getFile($event, filesParams)"></IncidentsTypesReportTable>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import IncidentsTypesReportTable from '@/components/reports/IncidentsTypesReportTable';
import filtering from '@/mixins/filtering';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import reportsFiles from '@/mixins/reportsFiles';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { CONVERT_TO_UTC } from '@/helpers/dates';

export default {
    name: 'IncidentsTypesReport',
    mixins: [
        filtering,
        debouncedEmployees,
        debouncedIncidentTypes,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        reportsFiles
    ],
    components: { FiltersBar, IncidentsTypesReportTable },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        debouncedFetchIncidentsTypesReports: null
    }),
    computed: {
        filtersConfig () {
            return [
                new DatePickerFilter({
                    model: FILTERS.START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS.END_DATE,
                    label: this.$t('labels.date_to')
                }),
                new AutocompleteFilter({
                    model: FILTERS.PERSON_ID,
                    label: this.$t('labels.person'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES,
                    lazy: this.getEmployeesLazyLoading,
                    searchCallback: this.debouncedGetEmployees,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'employees'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.INCIDENT_TYPE_ID,
                    label: this.$t('navigation.incidents_types_reports'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.INCIDENT_TYPE,
                    lazy: this.getIncidentsTypesLazyLoading,
                    searchCallback: this.debounceGetTypes,
                    items: {
                        obj: 'getters',
                        module: 'incidents',
                        prop: 'filterableTypesList'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customer'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.site'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    referralCallback: this.referralSiteCallback,
                    searchCallback: this.debouncedGetSites,
                    searchOptions: {
                        reference: FILTERS.CUSTOMER_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    searchOptions: {
                        reference: FILTERS.SITE_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        },
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapState('employees', [
            'totalEmployeesAmount'
        ]),
        ...mapGetters('employees', [
            'employees'
        ]),
        filesParams () {
            return {
                kind: 'incident_kinds',
                ...this.generateFiltersForRequest()
            };
        }
    },
    methods: {
        generateFiltersForRequest () {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.START_DATE) {
                        params[`filter[${f}]`] = CONVERT_TO_UTC(`${this.filters[f]} 00:00:00`);
                    } else if (f === FILTERS.END_DATE) {
                        params[`filter[${FILTERS.END_DATE}]`] = CONVERT_TO_UTC(`${this.filters[f]} 23:59:59`);
                    } else {
                        params[`filter[${f}]`] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        },
        fetchIncidentsTypesReports () {
            this.loader = true;

            const params = {
                ...this.generateFiltersForRequest()
            };

            return this.$store.dispatch('reports/getIncidentsKindsReports', params)
                .finally(() => {
                    this.loader = false;
                });
        }
    },
    created () {
        this.debouncedFetchIncidentsTypesReports = debounce(this.fetchIncidentsTypesReports, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchIncidentsTypesReports);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.INCIDENTS_TYPES_REPORT, vm.$abilitySubjects.REPORTS)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employees/getEmployees')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.PERSON_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('incidents/getIncidentTypes')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.INCIDENT_TYPE_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('customers/getCustomers')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchIncidentsTypesReports();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};
</script>

<style scoped>

</style>
